<template>
  <div class="imgRestore">
    <div class="modelBox" v-if="windowWidth">
      <span v-show="language_type" class="modelTitle">模型选择：</span>
      <span v-show="!language_type" class="modelTitle">Model selection：</span>
      <div
        :class="PitchOn_first ? 'PitchOn_first' : 'modelSelect'"
        @click="modelFirst"
      >
        SEED-X-I-17B(en)
      </div>
    </div>
    <div class="functionBox" v-if="windowWidth">
      <div
        class="functionLeft"
      >
        <div
          class="newimgUrl"
          v-show="showImage"
          @mouseover="mouseoverhandle"
          @mouseleave="mouseleavehandle"
        >
          <el-image
            class="imgUrl"
            :src="NewimgUrl"
            :preview-src-list="srcList"
            @click="getSrcS(NewimgUrl)"
          >
          </el-image>
          <div class="mask" v-show="mask">
            <i class="el-icon-delete" @click="handleRemove"></i>
          </div>
        </div>
        <el-upload
          action="#"
          list-type="picture-card"
          :auto-upload="false"
          :limit="1"
          :show-file-list="false"
          :on-change="handlechange"
          class="upload"
          v-loading="loading"
          element-loading-background="#e2e8ef"
          v-show="!showImage"
          :file-list="fileList"
        >
          <i slot="default" class="el-icon-plus"></i>
          <span class="text" v-show="language_type">点击上传</span>
          <span class="text_en" v-show="!language_type">Click to upload</span>
        </el-upload>
        <el-input
          type="textarea"
          :rows="3"
          :placeholder="
            language_type
              ? '输入文本或添加图像，然后按提交'
              : 'Enter text or add image, and press submit'
          "
          v-model="textarea"
        >
        </el-input>
        <div class="btn_box">
          <el-button @click="addimage" type="info" plain>{{
            language_type ? "添加图片" : "Add Image"
          }}</el-button>
          <el-button @click="addtext" type="info" plain>{{
            language_type ? "添加文本" : "Add Text"
          }}</el-button>
          <el-button @click="submit(true)" class="submit" type="info" plain>{{
            language_type ? "确认提交" : "Submit"
          }}</el-button>
        </div>
        <div class="setting_box">
          <li>
            <p class="label" v-show="language_type">
              最多输出token数：<span class="num">{{ maxNewTokens }}</span>
            </p>
            <p class="label_en" v-show="!language_type">
              Max Output Tokens：<span class="num">{{ maxNewTokens }}</span>
            </p>
            <el-slider
              v-model="maxNewTokens"
              :max="1024"
              :min="64"
              :step="64"
            ></el-slider>
          </li>
          <li>
            <p class="label" v-show="language_type">
              最多对话轮数：<span class="num">{{ max_turns }}</span>
            </p>
            <p class="label_en" v-show="!language_type">
              Max History Rounds：<span class="num">{{ max_turns }}</span>
            </p>
            <el-slider
              v-model="max_turns"
              :max="5"
              :min="1"
              :step="1"
            ></el-slider>
          </li>
          <li class="radio">
            <p class="label" v-show="language_type">强制出图：</p>
            <p class="label_en" v-show="!language_type">
              Force Image Generation：
            </p>
            <el-radio size="small" v-model="force_img_gen" :label="true" border
              >true</el-radio
            >
            <el-radio size="small" v-model="force_img_gen" :label="false" border
              >false</el-radio
            >
          </li>
          <li class="radio">
            <p class="label" v-show="language_type">强制出检测框：</p>
            <p class="label_en" v-show="!language_type">Force Bounding Box：</p>
            <el-radio size="small" v-model="force_bbox" :label="true" border
              >true</el-radio
            >
            <el-radio size="small" v-model="force_bbox" :label="false" border
              >false</el-radio
            >
          </li>
          <li class="radio">
            <p class="label" v-show="language_type">强制优化生成图像：</p>
            <p class="label_en" v-show="!language_type">
              Force Polishing Generated Image：
            </p>
            <el-radio size="small" v-model="force_polish" :label="true" border
              >true</el-radio
            >
            <el-radio size="small" v-model="force_polish" :label="false" border
              >false</el-radio
            >
          </li>
        </div>
      </div>
      <div class="functionRight">
        <p class="pending" v-show="resqustLoad">
          processing | {{ pendingTime.toFixed(1) }}s
        </p>
        <div class="message-box">
          <div
            v-for="(item, index) in messageList"
            :key="index"
            :class="item.type"
          >
            <div v-for="(e, i) in item.info" :key="i">
              <div
                class="content"
                :class="e.type == 'image' ? 'imageContent' : ''"
              >
                <span v-if="e.type == 'text'" class="sanjiao"></span>
                <p v-if="e.type == 'text'">{{ e.text }}</p>
                <div v-else class="img_box">
                  <el-image
                    class="messageImg"
                    v-for="(a, j) in e.url"
                    :key="j"
                    :src="a"
                    :preview-src-list="srcList"
                    @click="getSrcS(a)"
                  >
                  </el-image>
                </div>
              </div>
            </div>
          </div>
          <div class="loading" v-show="resqustLoad">
            <i class="el-icon-loading"></i>
          </div>
        </div>
        <div class="btn-box">
          <el-button
            @click="Regenerate"
            :disabled="disabledclear"
            icon="el-icon-refresh"
            type="info"
            plain
            >{{ language_type ? "重新生成" : "Regenerate" }}</el-button
          >
          <el-button
            @click="clearhistory"
            :disabled="disabledclear"
            icon="el-icon-delete"
            type="info"
            plain
            >{{ language_type ? "清除记录" : "Clear history" }}</el-button
          >
        </div>
      </div>
      <div class="example_box">
        <div class="left">
          <p class="tit">
            <i class="el-icon-s-unfold"></i
            >{{ language_type ? "输入示例" : "Input examples" }}
          </p>
          <el-table
            :data="tableData"
            border
            stripe
            style="width: 100%; cursor: pointer"
            @row-click="chooseImageAndText"
          >
            <el-table-column
              prop="date"
              :width="200"
              :label="language_type ? '示例图片' : 'input_image'"
              align="center"
            >
              <template slot-scope="scope">
                <div class="img_box">
                  <img :src="scope.row.src" alt="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="text"
              :label="language_type ? '示例文本' : 'input_text'"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <p class="tit">
            <i class="el-icon-s-unfold"></i
            >{{ language_type ? "输入示例" : "Input examples" }}
          </p>
          <li
            @click="chooseText(item)"
            v-for="(item, index) in textList"
            :key="index"
          >
            {{ item }}
          </li>
        </div>
      </div>
      <div class="moreImg">
        <p class="tit">
          <i class="el-icon-s-opportunity"></i
          >{{ language_type ? "更多玩法" : "More Example" }}
        </p>
        <el-image
          class="imgUrl"
          src="https://50058.gzc.svp.tencent-cloud.com/0b53jeaf4aaa6eagrl2lubtiusodlzeqaxqa.f0.jpg?dis_k=99a1c8d2d126673175c427ce36e22462&dis_t=1716205241"
          :preview-src-list="srcList"
          @click="
            getSrcS(
              'https://50058.gzc.svp.tencent-cloud.com/0b53jeaf4aaa6eagrl2lubtiusodlzeqaxqa.f0.jpg?dis_k=99a1c8d2d126673175c427ce36e22462&dis_t=1716205241'
            )
          "
        >
        </el-image>
      </div>
      <div class="btnBox">
        <p class="cooperation" v-show="language_type">
          商务合作、批量化调用等请联系 yixiaoge@tencent.com
        </p>
        <p class="cooperation" v-show="!language_type">
          Please contact yixiaoge@tencent.com for business collaborations, bulk
          invocation, and other related inquiries.
        </p>
        <div class="btnRight">
          <span class="shareTo" v-show="language_type">分享至：</span>
          <span class="shareTo" v-show="!language_type">Share To :</span>
          <div class="shareIcon" @click="wxShareAppMessage">
            <img
              class="normal_img"
              src="../../../assets/img/icon/wx1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/wx.png"
              alt=""
            />
            <div class="wx_code">
              <div class="code" v-show="language_type" ref="qrCodeUrl"></div>
              <div class="code" v-show="!language_type" ref="qrCodeUrl2"></div>
              <p v-show="language_type">分享到微信朋友圈</p>
              <p v-show="!language_type">Share with wechat</p>
            </div>
          </div>
          <div class="shareIcon" @click="share_weibo">
            <img
              class="normal_img"
              src="../../../assets/img/icon/weibo1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/weibo.png"
              alt=""
            />
          </div>
          <div class="shareIcon" @click="shareToQQ">
            <img
              class="normal_img"
              src="../../../assets/img/icon/qq1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/qq.png"
              alt=""
            />
          </div>
          <div class="shareIcon" @click="shareToDouban">
            <img
              class="normal_img"
              src="../../../assets/img/icon/dou1.png"
              alt=""
            />
            <img
              class="hover_img"
              src="../../../assets/img/icon/dou.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="functionBox_mini" v-if="windowWidth == false">
      <p class="message" v-if="language_type">
        建议在PC端打开链接或扫描二维码体验Demo
      </p>
      <p class="message" v-if="!language_type">
        It is recommended to open the link or scan the QR code on the PC side to
        experience the Demo
      </p>
      <div class="wxapp_box">
        <img src="../../../assets/img/wxapp.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import wx from "weixin-js-sdk";
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  data() {
    return {
      windowWidth: true,

      //新照片
      NewimgUrl: "",
      showImage: false,
      isActice: 0,
      value: 50,
      suffix: "", //文件拓展名
      ImgName: "", //文件名
      strImgName: "",
      downImg: "",
      uploadtake: false,
      dowmtake: false,
      mouse_disable: false,
      first: 1,
      loading: false,
      language_type: true,
      canvasWidth: 0,
      canvasHeight: 0,
      downImgType: "",
      srcList: [],
      //模型选择
      PitchOn_first: true,
      PitchOn_last: false,
      PitchOn: false,
      parameter: "",
      file: "",
      file2: "",
      dialogVisible: false,
      maxNewTokens: 768,
      max_turns: 3,
      force_img_gen: false,
      force_bbox: false,
      force_polish: true,
      inputVisible: false,
      inputValue: "",
      dialogVisibleTag: false,
      dialogImageUrl: "",
      textarea: "",
      fileList: [],
      mask: false,
      messageList: [],
      disabledclear: true,
      resqustLoad: false,
      tableData: [
        {
          src: "https://50058.gzc.svp.tencent-cloud.com/0b53caackaaa5uadlmkhe5tiuegdeuiaajia.f0.png?dis_k=17553b41d59f1f5147750eb80e0fca93&dis_t=1715933438",
          text: "Can I conntect with an advisor on Sunday?",
        },
        {
          src: "https://50058.gzc.svp.tencent-cloud.com/0b53pyagyaaayyajaj2lsntiu7wdnr7aa3aa.f0.jpg?dis_k=b64c4e714781dc7e5fb73229b49d5ae6&dis_t=1716255930",
          text: "Is there anything in the image that can protect me from catching the flu virus when I go out? Show me the location.",
        },
        {
          src: "https://50058.gzc.svp.tencent-cloud.com/0b53zmab6aaaryaa3jch4jtivs6dd7fqahya.f0.jpg?dis_k=a49abf3ec3ac25373f252df730169cc4&dis_t=1715933766",
          text: "What is the object pointed by the red arrow?",
        },
        {
          src: "https://50058.gzc.svp.tencent-cloud.com/0b53eeaccaaawaadh4chejtiuiodeeqqaiia.f0.png?dis_k=8154640e4970854015f5fa85bc6d498b&dis_t=1715933841",
          text: "Where was this image taken? Explain your answer.",
        },
        {
          src: "https://50058.gzc.svp.tencent-cloud.com/0b53l4aemaaasqafjqchsztiux6dizpqarqa.f0.png?dis_k=e5edd9d74e4fbf4daefcffaff1d6835f&dis_t=1715933900",
          text: "How long does it take to make GPT-4 safer?",
        },
        {
          src: "https://50058.gzc.svp.tencent-cloud.com/0b53vuaciaaa3aado7ch3ntivlodeswqajaa.f0.png?dis_k=b2f5ef1af86dbea6d6bf43008d21f063&dis_t=1715934070",
          text: "Please provide a comprehensive description of this image.",
        },
      ],
      textList: [
        "I want to build a two story cabin in the woods, with many commanding windows. Can you show me a picture?",
        "Use your imagination to design a concept image for Artificial General Intelligence (AGI). Show me an image.",
        "Can you design an illustration for “The Three-Body Problem” to depict a scene from the novel? Show me a picture.",
        "My four year old son loves toy trains. Can you design a fancy birthday cake for him? Please generate a picture.",
        "Generate an image of a portrait of young nordic girl, age 25, freckled skin, neck tatoo, blue eyes 35mm lens, photography, ultra details.",
        "Generate an impressionist painting of an astronaut in a jungle.",
      ],
      pendingTime: 0,
    };
  },
  mounted() {
    if (window.innerWidth > 1032) {
      this.creatQrCode();
    }
    if (this.$route.path.slice(1, 3) == "zh") {
      this.language_type = true;
    } else {
      this.language_type = false;
    }
  },
  watch: {
    $route(to) {
      if (to.path.slice(1, 3) == "zh") {
        this.language_type = true;
      } else {
        this.language_type = false;
      }
    },
  },
  methods: {
    modelFirst() {
      this.PitchOn_first = true;
      this.PitchOn_last = false;
      this.PitchOn = false;
      this.parameter = "abc";
      this.sendFn();

      if (this.parameter == "abc") {
        this.NewimgUrl = this.imgList[this.isActice].url2;
      }
    },
    modelLast() {
      this.PitchOn_last = true;
      this.PitchOn_first = false;
      this.PitchOn = false;
      this.parameter = "def";
      this.sendFn();
      if (this.parameter == "def") {
        this.NewimgUrl = this.imgList[this.isActice].url3;
      }
    },
    choores() {
      this.PitchOn_last = false;
      this.PitchOn_first = false;
      this.parameter = "defs";
      this.PitchOn = true;
      this.sendFn();
      if (this.parameter == "defs") {
        this.NewimgUrl = this.imgList[this.isActice].url4;
      }
    },
    sendFn() {
      if (this.file !== "") {
        this.uploadImage(this.file);
      }
    },
    getSrc(imgUrl) {
      this.srcList = [];
      this.srcList.push(imgUrl);
    },
    getSrcS(NewimgUrl) {
      this.srcList = [];
      this.srcList.push(NewimgUrl);
    },
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
    changImg(item, index) {
      this.imgUrl = item.url;
      this.NewimgUrl = item.url2;
      this.isActice = index;
      this.file = "";
      if (index == 0) {
        this.tagList = ["spotted dog", "black dog", "golden dog"];
      } else if (index == 1) {
        this.tagList = ["white bird"];
      } else if (index == 2) {
        this.tagList = ["orance", "fork", "plate"];
      }
    },
    watchTags(i) {
      if (i != "MS-COCO") {
        return;
      } else {
        this.dialogVisibleTag = true;
      }
    },
    handleClose(index) {
      this.tagList.splice(index, 1);
    },
    handlechange(file, fileList) {
      if (
        ![
          "image/png",
          "image/jpeg",
          "image/jpeg",
          "png",
          "jpg",
          "jpeg",
        ].includes(file.raw.type)
      ) {
        this.$message({
          type: "error",
          message: this.language_type ? "文件格式错误！仅支持png,jpg,jpeg格式" : "File format error! Only supports PNG, JPG, and JPEG formats",
        });
        this.fileList = [];
        return;
      }
      let jhUrl = "https://arc.tencent.com/identify_pictures";
      let jhForData = new FormData();
      jhForData.append("file1", file.raw);
      this.loading = true;
      this.axios.post(jhUrl, jhForData).then((jhRes) => {
        if (jhRes.data.errcode != 0) {
          this.$message({
            type: "error",
            message: this.language_type
              ? "图片可能涉及违规！"
              : "The image may involve violations",
          });
          this.loading = false;
          this.fileList = [];
          return;
        }
        this.fileList = fileList;
        var reader = new FileReader();
        reader.onloadend = (e) => {
          this.loading = false;
          var objSrc = e.target.result;
          var imageObj = new Image();
          imageObj.src = objSrc;
          imageObj.onload = () => {
            this.NewimgUrl = e.target.result;
            this.showImage = true;
          };
        };
        reader.readAsDataURL(file.raw);
      });
    },
    clearhistory() {
      this.messageList = [];
      this.disabledclear = true;
    },
    Regenerate() {
      this.messageList.pop();
      this.disabledclear = true;
      this.changeScreen("message-box");
      this.submit(false);
    },
    chooseText(e) {
      this.textarea = e;
    },
    async chooseImageAndText(row) {
      this.fileList = [];
      this.textarea = row.text;
      this.showImage = true;
      if (row.text == "What is the object pointed by the red arrow?") {
        this.NewimgUrl = await this.base64(row.src);
        let file = this.btof(this.NewimgUrl, "example");
        this.fileList.push({
          raw: file,
        });
      } else {
        let imageT = new Image();
        imageT.src = row.src;
        imageT.setAttribute("crossOrigin", "Anonymous");
        imageT.onload = () => {
          let base64 = this.getBase64Image(imageT);
          this.NewimgUrl = base64;
          let file = this.btof(base64, "example");
          this.fileList.push({
            raw: file,
          });
        };
      }
    },
    handleRemove() {
      this.fileList = [];
      this.NewimgUrl = "";
      this.showImage = false;
    },
    mouseoverhandle() {
      this.mask = true;
    },
    mouseleavehandle() {
      this.mask = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    addimage() {
      if (this.NewimgUrl == "") {
        return;
      } else {
        let list = this.messageList;
        if (list.length == 0 || list[list.length - 1].type == "resview") {
          let arr = [this.NewimgUrl];
          let brr = [this.fileList[0].raw];
          this.messageList.push({
            type: "send",
            info: [
              {
                type: "image",
                url: arr,
                file: brr,
              },
            ],
          });
        } else {
          let brr = [this.NewimgUrl];
          let crr = [this.fileList[0].raw];
          this.messageList[this.messageList.length - 1].info.push({
            type: "image",
            url: brr,
            file: crr,
          });
        }
        this.handleRemove();
        this.changeScreen("message-box");
      }
    },
    addtext() {
      if (this.textarea.trim() == "") {
        return;
      } else {
        let list = this.messageList;
        if (list.length == 0 || list[list.length - 1].type == "resview") {
          this.messageList.push({
            type: "send",
            info: [
              {
                type: "text",
                text: this.textarea,
              },
            ],
          });
        } else {
          this.messageList[this.messageList.length - 1].info.push({
            type: "text",
            text: this.textarea,
          });
        }
        this.textarea = "";
        this.changeScreen("message-box");
      }
    },
    changeScreen(name) {
      let i = setTimeout(() => {
        let box = document.getElementsByClassName(name);
        box[0].scrollTop = box[0].scrollHeight;
        clearTimeout(i);
      }, 100);
    },
    submit(e) {
      var that = this;
      if (e) {
        this.addimage();
        this.addtext();
      }
      this.resqustLoad = true;
      let time = setInterval(() => {
        this.pendingTime += 0.1;
      }, 100);
      if (
        this.messageList.length == 0 ||
        this.messageList[this.messageList.length - 1].type == "resview"
      ) {
        this.resqustLoad = false;
        this.pendingTime = 0;
        clearInterval(time);
        return;
      } else if (this.messageList.length > this.max_turns * 2) {
        this.messageList.push({
          type: "resview",
          info: [
            {
              type: "text",
              text: "Error: History exceeds maximum rounds, please clear history and restart.",
            },
          ],
        });
        this.resqustLoad = false;
        this.pendingTime = 0;
        clearInterval(time);
        this.disabledclear = false;
        this.changeScreen("message-box");
        return;
      }
      let imgT =
        "https://50058.gzc.svp.tencent-cloud.com/0b53oibwiaadxaaozsg3xjrys4wdmrzagzaa.f0.png?dis_k=867894a49d34b36a9749f3589af8fcf1&dis_t=1675135178";
      let imageT = new Image();
      imageT.src = imgT;
      imageT.setAttribute("crossOrigin", "Anonymous");
      imageT.onload = function () {
        var base64 = that.getBase64Image(imageT);
        var file2 = that.btof(base64, "test");
        let len = that.messageList.length;
        if (that.messageList[len - 1].type == "resview") {
          return;
        } else {
          let arr = that.messageList;
          let input_data = [];
          for (let index = 0; index < arr.length; index++) {
            let list = [];
            let text = "";
            for (let k = 0; k < arr[index].info.length; k++) {
              if (arr[index].info[k].type == "text") {
                text += arr[index].info[k].text;
              } else {
                let brr = arr[index].info[k].url;
                for (let j = 0; j < brr.length; j++) {
                  list.push(brr[j].split(",")[1]);
                  text += "<image>";
                }
              }
            }
            if (arr[index].type == "send") {
              input_data.push({
                role: "[INST]",
                message: {
                  images: list,
                  text,
                },
              });
            } else {
              input_data.push({
                role: "[/INST]",
                message: {
                  images: list,
                  text,
                },
              });
            }
          }
          var forData = new FormData();
          forData.append("file_yan_zheng", file2);
          forData.append("input_data", JSON.stringify({ input_data }));
          forData.append("max_new_tokens", that.maxNewTokens);
          forData.append("force_boi", that.force_img_gen);
          forData.append("force_bbox", that.force_bbox);
          forData.append("force_polish", that.force_polish);
          let potss = forData;
          let urls = "https://arc.tencent.com/seed_generate/";
          // let urls = "http://11.141.1.107:8080/seed_generate/";
          that.axios
            .post(urls, potss, {})
            .then((res) => {
              let list = res.data.images;
              let text = res.data.text.replace(/<image>/g, "");
              if (text.trim() != "") {
                that.messageList.push({
                  type: "resview",
                  info: [
                    {
                      type: "text",
                      text,
                    },
                  ],
                });
              }
              for (let index = 0; index < list.length; index++) {
                let arr = [];
                arr.push("data:image/png;base64," + list[index]);
                if (text.trim() != "") {
                  that.messageList[that.messageList.length - 1].info.push({
                    type: "image",
                    url: arr,
                  });
                } else {
                  that.messageList.push({
                    type: "resview",
                    info: [
                      {
                        type: "image",
                        url: arr,
                      },
                    ],
                  });
                }
              }
              that.resqustLoad = false;
              that.pendingTime = 0;
              clearInterval(time);
              that.disabledclear = false;
              that.changeScreen("message-box");
              that.$message({
                type: "success",
                message: that.language_type ? "加载成功" : "Success",
              });
            })
            .catch((err) => {
              that.resqustLoad = false;
              that.pendingTime = 0;
              clearInterval(time);
              if (
                that.messageList.length > 1 &&
                that.messageList[that.messageList.length - 1].type == "resview"
              ) {
                that.disabledclear = false;
              } else {
                that.disabledclear = true;
              }
              if (err.status == "504") {
                that.$message({
                  type: "error",
                  message: that.language_type
                    ? "服务器请求超时，请稍后再试"
                    : "Server request timeout, please try again later",
                });
              } else if (err.status == "400") {
                that.$message({
                  type: "warning",
                  message: that.language_type
                    ? "服务器开小差了，请稍后再试"
                    : "The server has deviated. please try again later",
                });
              } else if (err.status == "403") {
                that.$message({
                  type: "warning",
                  message: that.language_type
                    ? "服务器拒绝了我们的请求，请稍后再试"
                    : "The server has rejected our request, please try again later",
                });
              } else if (err.status == "404") {
                that.$message({
                  type: "warning",
                  message: that.language_type
                    ? "网页不存在，请刷新页面"
                    : "The webpage does not exist, please refresh the page",
                });
              } else {
                that.$message({
                  type: "error",
                  message: that.language_type
                    ? "当前调用的人太多啦，请稍后再试"
                    : "Too many people are calling now. Please try again later",
                });
              }
            });
        }
      };
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tagList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    sureData() {
      this.dialogVisible = false;
      this.dialogData.maxNewTokens = this.maxNewTokens;
      this.dialogData.max_turns = this.max_turns;
    },
    closedDialog() {
      this.maxNewTokens = this.dialogData.maxNewTokens;
      this.max_turns = this.dialogData.max_turns;
    },
    uploadImage(e) {
      var that = this;
      that.uploadtake = true;
      this.file = e;
      var file = e.target.files[0];
      let imgT =
        "https://50058.gzc.svp.tencent-cloud.com/0b53oibwiaadxaaozsg3xjrys4wdmrzagzaa.f0.png?dis_k=867894a49d34b36a9749f3589af8fcf1&dis_t=1675135178";
      let imageT = new Image();
      imageT.src = imgT;
      imageT.setAttribute("crossOrigin", "Anonymous");
      imageT.onload = function () {
        var base64 = that.getBase64Image(imageT);
        // document.getElementById("img")['src'] = base64;
        //转换base64到file
        var file2 = that.btof(base64, "test");
        var reader = new FileReader();
        reader.onloadend = (f) => {
          that.loading = true;
          var objSrc = f.target.result;
          var imageObj = new Image();
          imageObj.src = objSrc;
          imageObj.onload = () => {
            if (imageObj.width > 4000 || imageObj.height > 4000) {
              that.$message({
                type: "warning",
                message: that.language_type
                  ? "图片尺寸过大，请重新上传"
                  : "The picture size is too large, please upload it again",
              });
              that.loading = false;
            } else if (imageObj.width < 400 || imageObj.height < 400) {
              that.$message({
                type: "warning",
                message: that.language_type
                  ? "图像分辨率太小，仅限分辨率大于400*400的图像"
                  : "Image resolution is too small, only images with a resolution greater than 400*400",
              });
              that.loading = false;
            } else {
              that.ImgName = file.name; //获取文件名称
              that.suffix = that.ImgName.substring(
                that.ImgName.lastIndexOf(".") + 1
              ); //获取拓展名
              that.strImgName = that.ImgName.split(".")[0];
              that.file = file;
              that.file2 = file2;
              that.NewimgUrl = f.target.result;
              let str =
                "person, bicycle, car, motorcycle, airplane, bus, train, truck, boat, traffic light, fire hydrant, stop sign, parking meter, bench, bird, cat, dog, horse, sheep, cow, elephant, bear, zebra, giraffe, backpack, umbrella, handbag, tie, suitcase, frisbee, skis, snowboard, sports ball, kite, baseball bat, baseball glove, skateboard, surfboard, tennis racket, bottle, wine glass, cup, fork, knife, spoon, bowl, banana, apple, sandwich, orange, broccoli, carrot, hot dog, pizza, donut, cake, chair, couch, potted plant, bed, dining table, toilet, tv, laptop, mouse, remote, keyboard, cell phone, microwave, oven, toaster, sink, refrigerator, book, clock, vase, scissors, teddy bear, hair drier, toothbrush";
              that.tagList = ["MS-COCO"];
              that.tagListdig = str.split(",");
              var downloadType = f.target.result.slice(
                0,
                f.target.result.indexOf(",")
              );
              that.loading = false;
              that.downImgType = downloadType;
              that.$message({
                type: "success",
                message: that.language_type
                  ? "图片上传成功"
                  : "Image uploaded successfully",
              });
            }
          };
        };
        reader.readAsDataURL(file);
      };
    },
    base64(url) {
      return new Promise((resolve) => {
        const image = new Image();
        // 先设置图片跨域属性
        image.crossOrigin = "Anonymous";
        // 再给image赋值src属性，先后顺序不能颠倒
        image.src = url;
        image.onload = function () {
          const canvas = document.createElement("CANVAS");
          // 设置canvas宽高等于图片实际宽高
          canvas.width = image.width;
          canvas.height = image.height;
          canvas.getContext("2d").drawImage(image, 0, 0);
          // toDataUrl可以接收2个参数，参数一：图片类型，参数二： 图片质量0-1（不传默认为0.92）
          const dataURL = canvas.toDataURL("image/jpeg");
          resolve(dataURL);
        };
        image.onerror = () => {
          resolve({ message: "图片处理失败" });
        };
      });
    },
    downloadIamge() {
      if (this.downImg.length == "0") {
        this.$message({
          type: "warning",
          message: this.language_type
            ? "请先上传图片"
            : "Please upload pictures first",
        });
      } else {
        this.dowmtake = true;
        var Namesuffix = this.language_type ? "_识别后" : "_after";
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
          let bstr = atob(this.downImg.split(",")[1]);
          let n = bstr.length;
          let u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          let blob = new Blob([u8arr]);
          window.navigator.msSaveOrOpenBlob(
            blob,
            this.strImgName + Namesuffix + "." + this.suffix
          );
        } else {
          // 这里就按照chrome等新版浏览器来处理
          let a = document.createElement("a");
          a.href = this.downImg;
          a.setAttribute(
            "download",
            this.strImgName + Namesuffix + "." + this.suffix
          );
          a.click();
        }
      }
    },
    slideImg() {
      this.$refs.widthStyle.style.width = this.value + "%";
      this.$refs.leftStyle.style.left = this.value + "%";
      if (this.first == 1) {
        this.first++;
      } else {
        // 鼠标滑过效果互斥
        this.mouse_disable = true;
      }
    },
    mouseMove() {
      this.mouse_disable = false;
    },
    // 创建微信二维码
    creatQrCode() {
      var wx_qr = document.getElementsByClassName("code");
      var zh_path, en_path;
      if (this.$route.path.slice(1, 3) == "zh") {
        zh_path = this.$route.path;
        en_path = this.$route.path.replace("/zh/", "/en/");
      } else {
        zh_path = this.$route.path.replace("/en/", "/zh/");
        en_path = this.$route.path;
      }
      var path = "https://arc.tencent.com" + zh_path;
      var path2 = "https://arc.tencent.com" + en_path;
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: path, // 需要转换为二维码的内容
        width: wx_qr[0].offsetWidth,
        height: wx_qr[0].offsetHeight,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      var qrcode2 = new QRCode(this.$refs.qrCodeUrl2, {
        text: path2, // 需要转换为二维码的内容
        width: wx_qr[0].offsetWidth,
        height: wx_qr[0].offsetHeight,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      console.log(qrcode, qrcode2);
    },
    wxShareAppMessage() {
      wx.config({
        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx80d0a7314195c39e", // 必填，公众号的唯一标识
        timestamp: "1414587457", // 必填，生成签名的时间戳
        nonceStr: "Wm3WZYTPz0wzccnW", // 必填，生成签名的随机串
        signature: "0f9de62fce790f9a083d5c99e95740ceb90c27ed", // 必填，签名
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: "动漫增强", // 分享标题
          desc: "增强动漫和插画图像", // 分享描述
          link: "https://arc.tencent.com/ai-demos/imgRestore", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            "https://50058.gzc.svp.tencent-cloud.com/0b53lqadaaaat4ajeuavpzriuxgdgboaamaa.f0.jpg?dis_k=d924b0483964c3a9c031c8889a0c4c47&dis_t=1645585091", // 分享图标
          success: function () {
            alert("分享成功");
          },
          cancel: function () {
            alert("分享失败");
          },
        });
        wx.updateTimelineShareData({
          title: "动漫增强-增强动漫和插画图像", // 分享标题
          link: "https://arc.tencent.com/ai-demos/imgRestore", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            "https://50058.gzc.svp.tencent-cloud.com/0b53lqadaaaat4ajeuavpzriuxgdgboaamaa.f0.jpg?dis_k=d924b0483964c3a9c031c8889a0c4c47&dis_t=1645585091", // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
      wx.error(function () {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        alert("验证失败");
      });
    },
    // 分享到新浪微博
    share_weibo(event) {
      event.preventDefault();
      // shareUrl是微博的分享地址，（有资料说需要真实的appkey，必选参数，这里我没用appkey也可以正常分享）
      var _shareUrl = "http://v.t.sina.com.cn/share/share.php?";
      _shareUrl +=
        "url=" +
        encodeURIComponent("https://arc.tencent.com/ai-demos/imgRestore"); //参数url设置分享的内容链接
      _shareUrl +=
        "&title=" + encodeURIComponent("动漫增强-增强动漫和插画图像"); //参数title设置分享的标题
      _shareUrl +=
        "&pic=" + encodeURIComponent(this.NewimgUrl) + "&searchPic=true";
      // 保留当前页面,打开一个非tab页面（按需求来，可以新开标签页，也可以在当前页新开页面）
      window.open(
        _shareUrl,
        "_blank",
        "height=400, width=400",
        "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes"
      );
    },
    shareToQQ() {
      //此处分享链接内无法携带图片
      const share = {
        title: "动漫增强",
        desc: "增强动漫和插画图像",
        share_url: "https://arc.tencent.com/ai-demos/imgRestore",
      };
      var _shareUrl =
        "https://connect.qq.com/widget/shareqq/index.html?url=" +
        encodeURIComponent(share.share_url) +
        "&title=" +
        share.title +
        "&desc=" +
        share.desc;
      window.open(
        _shareUrl,
        "打开qq分享",
        "height=700, width=800,left=500,top=200",
        "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes"
      );
    },
    //分享到贴吧
    shareToTieba(event) {
      event.preventDefault();
      var _shareUrl = "http://tieba.baidu.com/f/commit/share/openShareApi?";
      const share = {
        title: "动漫增强-增强动漫和插画图像",
        share_url: "https://arc.tencent.com/ai-demos/imgRestore",
        pic: this.NewimgUrl,
        _width: 670,
        _height: 550,
        _top: (screen.height - 550) / 2,
        _left: (screen.width - 670) / 2,
      };
      _shareUrl += "title=" + encodeURIComponent(share.title || document.title); //分享的标题
      _shareUrl +=
        "&url=" + encodeURIComponent(share.share_url || document.location); //分享的链接
      _shareUrl += "&pic=" + encodeURIComponent(share.pic || ""); //分享的图片
      window.open(
        _shareUrl,
        "_blank",
        "width=" +
          share._width +
          ",height=" +
          share._height +
          ",left=" +
          share._left +
          ",top=" +
          share._top +
          ",toolbar=no,menubar=no,scrollbars=no,resizable=1,location=no,status=0"
      );
    },
    //分享到Facebook
    shareToFacebook(event) {
      event.preventDefault();
      var _shareUrl = "http://www.facebook.com/sharer/sharer.php?";
      const share = {
        title: "动漫增强-增强动漫和插画图像",
        share_url: "https://arc.tencent.com/ai-demos/imgRestore",
        _width: 670,
        _height: 550,
        _top: (screen.height - 550) / 2,
        _left: (screen.width - 670) / 2,
      };
      _shareUrl += "u=" + encodeURIComponent(share.share_url || location.href); //分享的链接
      _shareUrl += "&t=" + encodeURIComponent(share.title || document.title); //分享的标题
      window.open(
        _shareUrl,
        "_blank",
        "width=" +
          share._width +
          ",height=" +
          share._height +
          ",left=" +
          share._left +
          ",top=" +
          share._top +
          ",toolbar=no,menubar=no,scrollbars=no,resizable=1,location=no,status=0"
      );
    },
    //分享到豆瓣
    shareToDouban(event) {
      event.preventDefault();
      const share = {
        title: "动漫增强-增强动漫和插画图像",
        share_url: "https://arc.tencent.com/ai-demos/imgRestore",
        pic: this.NewimgUrl,
      };
      var _shareUrl = "http://shuo.douban.com/!service/share?";
      _shareUrl +=
        "href=" + encodeURIComponent(share.share_url || location.href); //分享的链接
      _shareUrl += "&name=" + encodeURIComponent(share.title || document.title); //分享的标题
      _shareUrl += "&image=" + encodeURIComponent(share.pic || ""); //分享的图片
      window.open(_shareUrl, "_blank");
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
      var dataURL = canvas.toDataURL("image/" + ext);
      return dataURL;
    },
    btof(data, fileName) {
      const dataArr = data.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/jpeg",
        endings: "native",
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".jpg", options);
    },
  },
};
</script>
    <style scoped lang='less'>
/deep/.el-loading-spinner .path {
  stroke: #babdc5;
}

/deep/.el-loading-mask {
  z-index: 25;
}

/deep/ .el-image__inner {
  object-fit: contain;
}

.imgRestore {
  max-width: 9.96rem;
  margin: 0 auto;

  .modelBox {
    display: flex;
    height: 0.46rem;
    align-items: center;
    margin-top: 0.3975rem;
    color: #222222;
    font-weight: 400;
    font-size: 0.14rem;

    .modelTitle {
      margin-right: 0.08rem;
    }

    .modelSelect,
    .PitchOn_first,
    .PitchOn_last {
      padding: 0.12rem 0.16rem;
      background: #f3f5f8;
      border-radius: 0.02rem;
      margin-left: 0.16rem;
      cursor: pointer;
    }

    .modelSelect:hover {
      color: #2e54eb;
    }

    .modelSelect:active {
      color: #2e54eb;
    }

    .PitchOn_first,
    .PitchOn_last {
      border: 0.01rem solid #2e54eb;
      color: #2e54eb;
      background-color: #ecf3ff;
      font-weight: 600;
    }
  }
  .functionBox {
    background-color: #f2f6fa;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2.93rem;
    margin-top: 0.12rem;
    .functionLeft {
      position: relative;
      margin-left: 0.1rem;
      padding-top: 0.23rem;
      width: 3.7rem;
      border-radius: 0.02rem;
      overflow: hidden;
      .upload {
        width: 3.7rem;
        height: 2.1rem;
        text-align: center;
        overflow: hidden;
      }
      /deep/.el-upload--picture-card {
        width: 2.1rem;
        height: 2.1rem;
        line-height: 2.1rem;
        position: relative;
      }
      .text {
        position: absolute;
        top: 15%;
        left: 38%;
      }
      .text_en {
        position: absolute;
        top: 15%;
        left: 31%;
      }
      .el-textarea {
        margin-top: 0.15rem;
      }
      .newimgUrl {
        position: relative;
        min-height: 2.1rem;
        background-color: #e2e8ef;

        .imgUrl {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          .el-icon-delete {
            font-size: 0.22rem;
            color: #ddd;
            cursor: pointer;
          }
        }
      }
      .btn_box {
        margin-top: 0.1rem;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-wrap: wrap;
        .el-button {
          width: 48%;
          margin: 0;
        }
        .submit {
          width: 100%;
          margin-top: 0.1rem;
        }
      }
      .setting_box {
        box-sizing: border-box;
        margin-top: 0.1rem;
        border: 1px solid #e5e7eb;
        padding: 0 0.08rem;
        border-radius: 0.04rem;
        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          /deep/.el-slider__button-wrapper {
            z-index: 1;
          }
          .label {
            width: 2.5rem;
            .num {
              color: #2d8cf0;
            }
          }
          .label_en {
            width: 3.3rem;
            .num {
              color: #2d8cf0;
            }
          }
          .el-slider {
            width: 100%;
          }
          .el-radio {
            margin: 0.05rem;
          }
        }
      }
    }
  }

  .functionRight {
    width: 6.16rem;
    height: 100%;
    max-height: 9rem;
    padding-left: 0.26rem;
    padding-right: 0.1rem;
    padding-top: 0.23rem;
    position: relative;
    .pending {
      width: 5.8rem;
      height: 0.2rem;
      position: absolute;
      top: 0.23rem;
      left: 0.26rem;
      background: #fff;
      text-align: right;
      z-index: 100;
      padding-right: 0.15rem;
      line-height: 0.2rem;
    }
    .message-box {
      width: 100%;
      height: 80%;
      max-height: 7.2rem;
      min-height: 5.9rem;
      padding-top: 0.2rem;
      box-sizing: border-box;
      background: #fff;
      border-radius: 0.08rem;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        // 隐藏滚动条将宽度设置为0
        background-color: #e8eaec;
        width: 0 !important;
      }
      .send,
      .resview {
        .content {
          position: relative;
          display: inline-block;
          max-width: 90%;
          border-radius: 0.08rem;
          box-sizing: border-box;
          padding: 0.2rem;
          margin-bottom: 0.15rem;
          text-align: left;
          .sanjiao {
            position: absolute;
            top: 0.2rem;
            right: -0.16rem;
            width: 0rem;
            border-top: 0.08rem solid transparent;
            border-bottom: 0.08rem solid transparent;
          }
          p {
            color: #000;
          }
          .img_box {
            display: flex;
            justify-content: center;
            align-content: center;
            .messageImg {
              display: inline-block;
              max-width: 2rem;
              // max-height: 1.6rem;
              margin: 0.06rem 0.06rem 0.06rem 0;
              border-radius: 0.1rem;
            }
          }
        }
      }
      .send {
        text-align: right;
        .content {
          margin-right: 2%;
          background: #3cb371;
          .sanjiao {
            right: -0.16rem;
            border-right: 0.08rem solid transparent;
            border-left: 0.08rem solid #3cb371;
          }
        }
      }
      .resview {
        text-align: left;
        .content {
          margin-left: 2%;
          background: #e5e7eb;
          .sanjiao {
            left: -0.16rem;
            border-right: 0.08rem solid #e5e7eb;
            border-left: 0.08rem solid transparent;
          }
        }
      }
      .imageContent {
        background: none !important;
        padding: 0 !important;
      }
      .loading {
        width: 96%;
        height: 0.6rem;
        border-radius: 0.2rem;
        box-sizing: border-box;
        margin-bottom: 0.15rem;
        margin-left: 2%;
        background: #e5e7eb;
        line-height: 0.6rem;
        text-align: center;
        .el-icon-loading {
          font-size: 24px;
        }
      }
    }
    .btn-box {
      margin-top: 0.1rem;
      text-align: right;
    }
  }
  .example_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0.1rem;
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-between;
    .left {
      width: 60%;
      .img_box {
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        overflow: hidden;
        border: 0.02rem solid #e5e7eb;
        border-radius: 0.08rem;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .tit {
        margin-bottom: 0.05rem;
        .el-icon-s-unfold {
          margin-right: 0.04rem;
        }
      }
    }
    .right {
      width: 38%;
      .tit {
        margin-bottom: 0.05rem;
        .el-icon-s-unfold {
          margin-right: 0.04rem;
        }
      }
      li {
        padding: 0.04rem 0.08rem;
        border: 0.02rem solid #e5e7eb;
        border-radius: 0.08rem;
        box-sizing: border-box;
        margin-bottom: 0.08rem;
        text-align: center;
        cursor: pointer;
      }
      li:hover {
        border: 0.02rem solid #3cb371;
      }
    }
  }
  .btnBox {
    width: 100%;
    padding-top: 0.2rem;
    padding-bottom: 0.1rem;
    box-sizing: content-box;
    position: relative;
    .cooperation {
      position: absolute;
      bottom: -0.25rem;
      left: 0.05rem;
      font-size: 0.14rem;
    }
    .options {
      position: absolute;
      top: 0.03rem;
      right: 2.6rem;
      color: #2d8cf0;
      cursor: pointer;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 0.2rem;
      }
    }

    .btnLeft {
      float: left;
      display: flex;
      margin-left: 0.3rem;
      .uploadBtn {
        background-color: #2e54eb;
        width: 1.6rem;
        height: 0.46rem;
        line-height: 0.46rem;
        border-radius: 0.02rem;
        color: #fff;
        font-size: 0.16rem;
        text-align: center;
        margin-right: 0.2rem;
        position: relative;
        cursor: pointer;

        .uploadInput {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          overflow: hidden;
          cursor: pointer;
          padding: 0.25rem;
        }
      }

      .uploadBtn:hover {
        background-color: #486dff;
      }

      .uploadBtn:active {
        background-color: #002acf;
      }

      .clickupload {
        background-color: #002acf;
      }

      .dowmBtn,
      .mouse_disable {
        width: 1.6rem;
        height: 0.46rem;
        line-height: 0.46rem;
        border-radius: 0.02rem;
        font-size: 0.16rem;
        text-align: center;
        border: 1px solid #2e54eb;
        color: #2e54eb;
        cursor: pointer;
      }

      .dowmBtn:hover {
        color: #fff;
        background-color: #486dff;
        border: 1px solid transparent;
      }

      .dowmBtn:active {
        color: #fff;
        background-color: #002acf;
        border: 1px solid transparent;
      }
    }

    .btnRight {
      height: 0.46rem;
      flex: 1;
      margin-right: 0.08rem;
      display: flex;
      align-items: center;
      float: right;

      .shareTo {
        font-weight: 600;
        font-family: PingFang SC;
        font-size: 0.14rem;
        line-height: 0.2rem;
        margin-right: 0.22rem;
        color: #222;
      }

      .shareIcon {
        position: relative;
        margin-right: 0.16rem;
        width: 0.28rem;
        height: 0.28rem;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }

        .normal_img {
          display: inline-block;
        }

        .hover_img {
          display: none;
        }

        .wx_code {
          width: 1.52rem;
          height: 1.8rem;
          background: #ffffff;
          border: 1px solid #d9e0e8;
          box-sizing: border-box;
          box-shadow: 0rem 0.04rem 0.15rem rgba(108, 121, 154, 0.201759);
          border-radius: 0.02rem;
          position: absolute;
          top: 0.28rem;
          left: -0.62rem;
          visibility: hidden;

          .code {
            width: 1.2rem;
            height: 1.2rem;
            margin: 0.16rem 0.16rem 0.08rem;
          }

          p {
            height: 0.2rem;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 600;
            font-size: 0.14rem;
            line-height: 0.2rem;
            color: #434343;
            text-align: center;
          }
        }
      }

      .shareIcon:hover {
        .normal_img {
          display: none;
        }

        .hover_img {
          display: inline-block;
        }

        .wx_code {
          visibility: inherit;
        }
      }

      .hover_img:active {
        opacity: 0.7;
      }
    }
  }
  .moreImg {
    width: 100%;
    height: 7.8rem;
    padding: 0 0.1rem;
    margin-bottom: 0.2rem;
    .tit {
      margin: 0.05rem 0;
      .el-icon-s-opportunity {
        margin-right: 0.04rem;
      }
    }
    .imgUrl {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .functionBox_mini {
    margin-top: 0.8rem;

    .message {
      width: 80%;
      margin: 0 auto;
      font-family: PingFangSC-Regular, sans-serif;
      font-size: 0.3rem;
      color: #666666;
      line-height: 0.52rem;
      text-align: center;
    }

    .wxapp_box {
      width: 3.4rem;
      height: 3.4rem;
      margin: 0.4rem auto 0;
      background: #ffffff;
      border: 1px solid #d9e0e8;
      box-sizing: border-box;
      box-shadow: 0px 0.04rem 0.15rem rgb(108 121 154 / 20%);
      border-radius: 0.02rem;
      text-align: center;
      padding-top: 0.2rem;

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
</style>